@keyframes showButton {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

#menu {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
#menu button {
    margin: 5px;
    opacity: 0;
    animation: showButton 1s 5s forwards;
    border: none;
    color: #FFFFFF;
    border-radius: 10px 10px 10px 10px;
    -webkit-border-radius: 10px 10px 10px 10px;
    -moz-border-radius: 10px 10px 10px 10px;
    font-family: Georgia;
    width: auto;
    height: auto;
    font-size: 14px;
    padding: 12px;
    box-shadow: 0px 2px 7px 0 #2E0505;
    -moz-box-shadow: 0px 2px 7px 0 #2E0505;
    -webkit-box-shadow: 0px 2px 7px 0 #2E0505;
    background-image: linear-gradient(to top, #ad1c20, #FA272E);
    background-color: #ED252C;
    cursor: pointer;
    order: 1;
}

#menu img {
    margin: 5px;
    opacity: 0;
    animation: showButton 1s 6s forwards;
    height: 50px;
}
#menu a {
    order: 3; 
}
#menu #legend {
    order: 4;
    display: block;
    height: inherit;
    width: 100px;
    background-color: #fff;
    padding: 5px;
    border-radius: 10px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 10px;
    display: none;
    opacity: 0;
}
#menu #legend img {
    height: inherit;
    width: 100%;
}
#menu #legend h4 {
    font-size:14px;
    margin: 0;
    text-align: center;
}
#menu #donate {
    background-image: linear-gradient(to top, #138123, #27d850);
    animation: showButton 1s 5.5s forwards;
    background-color: #34cd26;
    order: 2;
}

@media screen and (min-width: 600px) {
    #menu {
        flex-direction: column;
        width: 50%;
    }
    #menu button {
        padding: 15px 20px;
        font-size: 18px;
    }
    #menu #legend {
        display: block;
        animation: showButton 1s 6s forwards;
    }
}