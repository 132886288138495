#donate_modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    padding: 0px 20px;
    height: 100%;
    overflow: scroll;
}

#donate_modal h1 {
    margin-top: 0;
    text-align: center;
}

#donate_modal p {
    line-height: 28px;
    width: 100%;
    font-size: 18px;
}

#donate_modal img {
    width: 30px;
}