#about {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    padding: 0px 20px;
    height: 100%;
    overflow: scroll;
}

#about h1 {
    margin-top: 0;
    text-align: center;
}

#about p {
    line-height: 28px;
    width: 100%;
    font-size: 18px;
}

#about img {
    width: 30px;
}

#about .legend {
    width: 100px;
    float: right;
    margin-left: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
}