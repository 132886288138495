#map {
    height: 100vh;
    height: 100dvh;
    width: 100vw;
    max-height: 100%;
}

#status {
    position: absolute;
    bottom: 0;
    left: 10px;
    display: flex;
    background-color: #fff;
    font-size: 14px;
    padding: 10px;
    border: 1px solid #999;
    border-radius: 10px 10px 0 0;
}

#targetSanta {
    position: absolute;
    z-index: 10;
    right: 10px;
    bottom: 120px;
    border: 0;
    background: #efefef;
}

#targetSanta img {
    width: 50px;
}