@keyframes moveLogo {
    0% {
        margin-left: 8vw;
        margin-top: 20vh;
        width: 80vw;
    }
    100% {
        margin-top: 10px;
        margin-left: 10px;
        width: 40vw;
    }
}

@keyframes shrinkFont {
    0% {
        font-size: 24px;
    }
    100% {
        font-size: 16px;
    }
}

#hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    position: absolute;
    z-index: 10;
    margin-left: 8vw;
    margin-top: 20vh;
    top: 0;
    left: 0;
    width: 80vw;
    animation: moveLogo 3s 2s forwards;
    max-width: 600px;
}

#hero h2 {
    color: #ED252C;
    font-family:Georgia, 'Times New Roman', Times, serif;
    font-size: 24px;
    animation: shrinkFont 3s 2s forwards;
}
#logo {
    width: 90%;
}

@media screen and (min-width: 600px) {

    @keyframes moveLogo {
        0% {
            margin-left: 25vw;
            margin-top: 20vh;
            width: 80vw;
        }
        100% {
            margin-top: 10px;
            margin-left: 10px;
            width: 20vw;
        }
    }

    #hero {
        margin-left: 25vw;
        margin-top: 20vh;
    }
}