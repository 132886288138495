#location {
    padding: 10vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#location img {
    width: 80vw;
    max-width: 100%;
}
